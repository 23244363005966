import { useState, useEffect } from "react";
import db, { fireStorage, fireStorageRef } from "../../Firebase/base";
import { setItem, showMessageNotification } from "./../../utils/Functions"; // Utility dunctions
// Default Value
const languageKey = "language";
const themeKey = "theme";
const resizeKey = "resize";
const sidebarKey = "sidebar";
const pageLimit = 10;

/******************* 
@Purpose : Get User List
@Parameters : {}
@Author : INIC
******************/
export const getUsers =
  (callback, keyword = "", next, limit = true) =>
  async (dispatch) => {
    let payload = [], prevIndex = null;
    let qry = db.collection("user")
      .orderBy("full_name");
    if (keyword) {
      qry = qry.startAt(keyword)
        .endAt(keyword + "\uf8ff")
    }
    if (next) {
      prevIndex = next;
      qry = qry.startAfter(next);
    }
    if (limit) {
      qry = qry.limit(pageLimit);
    }
    qry.get().then((doc) => doc).catch(err => console.log(err));
      qry
      .get()
      .then((doc) => {
        
        let nextIndex = keyword === "" && doc.docs.length >= pageLimit ? doc.docs[doc.docs.length - 1] : null;
        doc.forEach((item) => {
          payload = {
            ...payload,
            [item.id]: { ...item.data(), userid: item.id },
          };
        });
        dispatch({ type: "GET_USERS", payload: payload, nextIndex:nextIndex, prevIndex: prevIndex });
        callback();
      })
      .catch((err) => {
        callback(err);
      });
    };
  
    /******************* 
@Purpose : Update User Status
@Parameters : {}
@Author : INIC
******************/
export const updateUserStatus = (id, status) => (dispatch) => {
  db.collection("user")
    .doc(id)
    .update({
      is_active: status,
    })
    .then(() => {
      dispatch({
        type: "EDIT_USER_STATUS",
        payload: {
          userid: id,
          is_active: status,
        },
      });
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

/******************* 
@Purpose : Get Master CMS
@Parameters : {}
@Author : INIC
******************/
export const getMasterCms =
  (callback, keyword = "") =>
  async (dispatch) => {
    let payload = [];
    db.collection("master_cms")
      .orderBy("type")
      .startAt(keyword)
      .endAt(keyword + "\uf8ff")
      .get()
      .then((doc) => {
        doc.forEach((item) => {
          payload = {
            ...payload,
            [item.id]: { ...item.data(), cmsid: item.id },
          };
        });
        dispatch({ type: "MASTER_CMS", payload: payload });
        callback();
      })
      .catch((err) => {
        callback(err);
      });
  };

/******************* 
@Purpose : Edit Master CMS
@Parameters : {}
@Author : INIC
******************/
export const updateMasterCms =
  ({ id, data, callback }) =>
  async (dispatch) => {
    db.collection("master_cms")
      .doc(id)
      .set(data)
      .then(() => {
        dispatch({
          type: "EDIT_MASTER_CMS",
          payload: { id: id, payload: data },
        });
        callback();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

/******************* 
@Purpose : Get Baneer List
@Parameters : {}
@Author : INIC
******************/
export const getBanners = (callback) => (dispatch) => {
  let payload = [];
  db.collection("banner_master")
    .get()
    .then((doc) => {
      doc.forEach((item) => {
        payload = {
          ...payload,
          [item.id]: {
            ...item.data(),
            bannerid: item.id,
          },
        };
      });
      dispatch({ type: "GET_BANNERS", payload: payload });
      callback();
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

/******************* 
@Purpose : Add Banner
@Parameters : {}
@Author : INIC
******************/
export const addBanner = (img, payload, callback) => (dispatch) => {
  fireStorageRef
    .child(payload.banner_img)
    .put(img)
    .then((snapshot) => {
      fireStorageRef
        .child(payload.banner_img)
        .put(img)
        .snapshot.ref.getDownloadURL()
        .then((downloadURL) => {
          db.collection("banner_master")
            .add({
              ref_link: payload.ref_link,
              expiry_date: payload.expiry_date,
              is_active: true,
              banner_img: downloadURL,
            })
            .then((docref) => {
              dispatch({
                type: "ADD_EDIT_BANNER",
                payload: {
                  ref_link: payload.ref_link,
                  expiry_date: payload.expiry_date,
                  bannerid: docref.id,
                  is_active: true,
                  banner_img: downloadURL,
                },
              });
              callback();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        })
        .catch((err) => {
          callback(err);
        });
    })
    .catch((err) => {
      callback(err);
    });
};

/******************* 
@Purpose : Update Banner
@Parameters : {}
@Author : INIC
******************/
export const updateBanner = (img, payload, callback) => (dispatch) => {
  fireStorageRef
    .child(payload.banner_img)
    .put(img)
    .then((snapshot) => {
      fireStorageRef
        .child(payload.banner_img)
        .put(img)
        .snapshot.ref.getDownloadURL()
        .then((downloadURL) => {
          db.collection("banner_master")
            .doc(payload.bannerid)
            .update({
              ref_link: payload.ref_link,
              expiry_date: payload.expiry_date,
              is_active: payload.is_active,
              banner_img: downloadURL,
            })
            .then((docref) => {
              dispatch({
                type: "ADD_EDIT_BANNER",
                payload: {
                  ref_link: payload.ref_link,
                  expiry_date: payload.expiry_date,
                  bannerid: payload.bannerid,
                  is_active: payload.is_active,
                  banner_img: downloadURL,
                },
              });
              callback();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        })
        .catch((err) => {
          callback(err);
        });
    })
    .catch((err) => {
      callback(err);
    });
};

/******************* 
@Purpose : Update Banner Status
@Parameters : {}
@Author : INIC
******************/
export const updateBannerStatus = (id, status) => (dispatch) => {
  db.collection("banner_master")
    .doc(id)
    .update({
      is_active: status,
    })
    .then(() => {
      dispatch({
        type: "EDIT_BANNER_STATUS",
        payload: {
          bannerid: id,
          is_active: status,
        },
      });
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

/******************* 
@Purpose : Delete Banner
@Parameters : {}
@Author : INIC
******************/
export const deleteBanner = (imgLink, id, callback) => (dispatch) => {
  fireStorage
    .refFromURL(imgLink)
    .delete()
    .then(() => {
      db.collection("banner_master")
        .doc(id)
        .delete()
        .then(() => {
          dispatch({
            type: "DELETE_BANNER",
            payload: {
              bannerid: id,
            },
          });
          callback();
        })
        .catch((err) => {
          callback(err);
        });
    })
    .catch((err) => {
      callback(err);
    });
};

/******************* 
@Purpose : Get Posts
@Parameters : {}
@Author : INIC
******************/
export const getPosts =(callback, keyword = "", next,limit=true) =>
  async (dispatch) => {
    let payload = [], prevIndex = null;
    let qry = db.collection("post").orderBy("title");
    if (keyword) {
      qry = qry.startAt(keyword)
        .endAt(keyword + "\uf8ff")
    }
    if (next) {
      prevIndex = next;
      qry = qry.startAfter(next);
    }
    if (limit) {
      qry = qry.limit(pageLimit);
    }
    qry.get().then((doc) => doc).catch(err => console.log(err));
      qry
      .get()
      .then((doc) => {
        let nextIndex = keyword === "" && doc.docs.length >= pageLimit ? doc.docs[doc.docs.length - 1] : null;
        doc.forEach((item) => {
          payload = {
            ...payload,
            [item.id]: { ...item.data(), userid: item.id },
          };
        });
        dispatch({ type: "GET_POSTS", payload: payload, nextIndex:nextIndex, prevIndex: prevIndex });
        callback();
      })
      .catch((err) => {
        callback(err);
      });
  };
     /******************* 
@Purpose : Update Post Status
@Parameters : {}
@Author : INIC
******************/
export const updatePostStatus = (id, status) => (dispatch) => {
  db.collection("post")
    .doc(id)
    .update({
      is_active: status,
    })
    .then(() => {
      dispatch({
        type: "EDIT_POST_STATUS",
        payload: {
          userid: id,
          is_active: status,
        },
      });
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};
    
  /******************* 
@Purpose : Get Posts Report
@Parameters : {}
@Author : INIC
******************/
export const getPostsReport =(callback, keyword = "", next) =>
  async (dispatch) => {
    let payload = [], prevIndex = null;
    let qry = db.collection("report")
      .orderBy("message");
    if (keyword) {
      qry = qry.startAt(keyword)
        .endAt(keyword + "\uf8ff")
    }
    if (next) {
      prevIndex = next;
      qry = qry.startAfter(next);
    }
    qry.get().then((doc) => doc).catch(err => console.log(err));
      qry
      .limit(pageLimit)
      .get()
      .then((doc) => {
        let nextIndex = keyword === "" && doc.docs.length >= pageLimit ? doc.docs[doc.docs.length - 1] : null;
        doc.forEach((item) => {
          payload = {
            ...payload,
            [item.id]: { ...item.data(), userid: item.id },
          };
        });
        dispatch({ type: "GET_POSTS_REPORT", payload: payload, nextIndex:nextIndex, prevIndex: prevIndex });
        callback();
      })
      .catch((err) => {
        callback(err);
      });
    };
/******************* 
/******************* 
@Purpose : Edit User
@Parameters : id
@Author : INIC
******************/
export const editUser = (id) => async (dispatch) => {
  dispatch({ type: "EDIT_USER", payload: id });
};
/******************* 
@Purpose : Edit Email
@Parameters : id
@Author : INIC
******************/
export const editEmail = (id) => async (dispatch) => {
  dispatch({ type: "EDIT_EMAIL", payload: id });
};
/******************* 
@Purpose : Change Language
@Parameters : body
@Author : INIC
******************/
export const changeLanguage = (body) => async (dispatch) => {
  setItem(languageKey, body.language);
  const payload = {
    data: body.language,
  };
  await dispatch({ type: "language", payload });
};
/******************* 
@Purpose : Change Theme
@Parameters : body
@Author : INIC
******************/
export const changeTheme = (body) => async (dispatch) => {
  setItem(themeKey, body);
  const payload = {
    data: body,
  };
  await dispatch({ type: "theme", payload });
};
/******************* 
@Purpose : Change Sidebar Collapse
@Parameters : body
@Author : INIC
******************/
export const sidebaropen = (body) => async (dispatch) => {
  setItem(sidebarKey, body);
  const payload = {
    data: body,
  };
  await dispatch({ type: "sidebar", payload });
};
/******************* 
@Purpose : Change Screen Resize Value
@Parameters : body
@Author : INIC
******************/
export const changeResize = (body) => async (dispatch) => {
  setItem(resizeKey, body);
  const payload = {
    data: body,
  };
  await dispatch({ type: "resize", payload });
};
/******************* 
@Purpose : Change Screen Resize Value
@Parameters :{}
@Author : INIC
******************/
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
