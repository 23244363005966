import { Select } from "antd";
import "antd/dist/antd.css";
import debounce from "debounce";
import $ from "jquery";
import _ from "lodash";
import "rc-pagination/assets/index.css";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
// Used for api call
import swal from "sweetalert";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import {
  getPosts,
  getPostsReport,
  getUsers,
  updatePostStatus,
} from "../../store/Actions";
import { showModalNotification } from "../../utils/Functions";
import ShowMoreText from "react-show-more-text";
$.DataTable = require("datatables.net");
require("datatables.net-responsive");
const editPostStatus = debounce((cb, id, status) => cb(id, status), 1000);
var { Option } = Select;
/******************* 
@Purpose : Used for users list view
@Parameter : props
@Author : INIC
******************/
function UserPostsReport(props) {
  const { reports, posts, users } = props;

  const [columnSettings, setColumnSettings] = useState({
    photo: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobile: true,
    emailVerificationStatus: true,
    status: true,
  });
  const [reportList, setPostsReportList] = useState(reports);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [userPosts, setUserPosts] = useState(posts);
  const [imageUrl, setImageUrl] = useState()

  useEffect(() => {
    props.getPosts((err) => {
      if (err)
        showModalNotification(err.message || "something went wrong", "danger");

      /******************* 
      @Purpose : Get Request for User Report
      @Parameter : Callback function
      @Author : INIC
      ******************/
      props.getPostsReport((err) => {
        if (err)
          showModalNotification(
            err.message || "something went wrong",
            "danger"
          );
        /******************* 
          @Purpose : Get Request for User list
          @Parameter : Callback function
          @Author : INIC
          ******************/
        props.getUsers((err) => {
          setLoading(false);
          if (err)
            showModalNotification(
              err.message || "something went wrong",
              "danger"
            );
        },"",false,false);
      });
    },"",false,false);
  }, []);

  useEffect(() => {
    setPostsReportList(reports);
  }, [reports]);

  /******************* 
  @Purpose : Used for pagination
  @Parameter : Pagination Type: Prev or Next
  @Author : INIC
  ******************/
  const paginationChange = (type) => {
    setLoading(true);
    props.getPostsReport(
      (err) => {
        if (err) {
          showModalNotification("Something Went Wrong", "error");
        }
        setLoading(false);
      },
      "",
      (type = "next" ? props.next : props.prev)
    );
  };

  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = debounce((keyword) => {
    setLoading(true);
    props.getPostsReport((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    }, keyword);
  }, 1000);

  /******************* 
  @Purpose : Used for handle UserStatus status
  @Parameter : {status,index}
  @Author : INIC
  ******************/
  const updatePostHandler = (key,status) => {
    let newUserPosts = { ...userPosts };
    newUserPosts[key].is_active = !status;
    setUserPosts(newUserPosts);
     editPostStatus(
       props.updatePostStatus,
       key,
       !status
     );
  };
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block overflow-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="content-header-title border-0 ">Users</li>
              </ol>
            </nav>
            <div class="content-area position-relative">
              <div class="grid">
                <div class="grid-content">
                  <div class="user-listing-filterOptions mb-2 d-block">
                    <div class="row mb-2">
                      <div class="col-sm-8 position-static">
                        <div class="left-buttons d-flex ">
                          <label>
                            <div className="search-input-wrapper position-relative">
                              <i className="bx bx-search"></i>{" "}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="search"
                                onChange={(evt) =>
                                  searchField(evt.target.value)
                                }
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? <Loader /> : null}
                </div>
              </div>
            </div>
            {!loading ? (
              <div>
                {reportList && reportList.length === 0 ? (
                  <h6
                    className="text-center text-danger not-found-txt"
                    colSpan="6"
                  >
                    No Records Found!
                  </h6>
                ) : (
                  reportList &&
                  Array.isArray(reportList) &&
                  reportList.map((report, key) => {
                    return (
                      <div key={report.document_id} className="row">
                        <div className="col-lg-12 col-xl-12">
                          <div class="card profile-card mb-5">
                            <div className="d-flex">
                              <div className="user-post-imgwrapper">
                                <div
                                  className="w-100 cursor-pointer"
                                  onClick={() => {
                                    setModalShow(true);
                                    setImageUrl(
                                      posts[report.entity_id].attachment
                                    );
                                  }}
                                >
                                  <img
                                    src={posts[report.entity_id].attachment}
                                  />
                                </div>
                                <div className="user-title-info user-details">
                                  <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                    <label className="mb-0" htmlFor="firstName">
                                      Status
                                    </label>
                                    <div className="w-100 ">
                                      <div className="custom-control cursor-pointer custom-switch light">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={userPosts[report.entity_id]?.user_id}
                                          checked={
                                            userPosts[report.entity_id]?.is_active
                                          }
                                          onChange={() => {
                                            updatePostHandler(
                                              report.entity_id,
                                              userPosts[report.entity_id]
                                                .is_active
                                            );
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={report.user_id}
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1 w-100">
                                <div className="mb-1">
                                  <div className="user-title-info user-details">
                                    <h4 className="report-post-title">
                                      <span>
                                        <ShowMoreText
                                          /* Default options */
                                          lines={2}
                                          more="Show more"
                                          less="Show less"
                                          className="content-css"
                                          anchorClass="toggle-showmore dark"
                                          expanded={false}
                                        >
                                          {posts[report.entity_id]?.title}
                                        </ShowMoreText>
                                      </span>
                                    </h4>
                                  </div>
                                  <div className="user-title-info user-details">
                                    <div className="userInfo d-flex align-items-center">
                                      <div className="m-2">
                                        <div className="user-image post-user-pic cursor-pointer">
                                          <img
                                            src={
                                              users[report.user_id]
                                                ?.profile_photo
                                            }
                                            alt="post-user-pic"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div className="user-name">
                                          <span className="text-readonly w-auto">
                                            {users[report.user_id]?.full_name}
                                          </span>
                                        </div>
                                        <div className="user-number">
                                          <span className="text-readonly w-auto">
                                            {
                                              users[report.user_id]
                                                ?.country_code
                                            }
                                            {
                                              users[report.user_id]
                                                ?.mobile_number
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="report-post-wrapper">
                                  <hr />
                                  <div className="user-title-info user-details">
                                    <span>Reported Concern</span>
                                    <h5 className="report-issue-msg mt-2">
                                      <span>
                                        <ShowMoreText
                                          /* Default options */
                                          lines={2}
                                          more="Show more"
                                          less="Show less"
                                          className="content-css"
                                          anchorClass="toggle-showmore"
                                          expanded={false}
                                        >
                                          {report.message}
                                        </ShowMoreText>
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="small-font text-readonly w-auto d-block mt-3">
                                    Reported By
                                  </div>
                                  <div className="user-title-info user-details">
                                    <div className="userInfo d-flex align-items-center">
                                      <div className="m-2">
                                        <div className="user-image post-user-pic cursor-pointer">
                                          <img
                                            src={
                                              users[report.sender_user_id]
                                                ?.profile_photo
                                            }
                                            alt="post-user-pic"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div className="user-name">
                                          <span className="text-readonly w-auto">
                                            {
                                              users[report.sender_user_id]
                                                ?.full_name
                                            }
                                          </span>
                                        </div>
                                        <div className="user-number">
                                          <span className="text-readonly w-auto">
                                            {
                                              users[report.sender_user_id]
                                                ?.country_code
                                            }
                                            {
                                              users[report.sender_user_id]
                                                ?.mobile_number
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            ) : null}
            {!loading ? (
              <div className="d-flex mt-3">
                {props.prev ? (
                  <Button
                    size="sm"
                    className="mr-2"
                    onClick={() => paginationChange("prev")}
                  >
                    Previous
                  </Button>
                ) : null}
                {props.next ? (
                  <Button size="sm" onClick={() => paginationChange("next")}>
                    Next
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
          <footer>
            <Modal
              dialogClassName="modal-90w"
              show={modalShow}
              onHide={() => setModalShow(false)}
            >
              <Modal.Body>
                <img src={imageUrl} alt="post-image"/>
              </Modal.Body>
            </Modal>
            <div className="footer-text d-flex align-items-centerf justify-content-between"></div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  posts: state.admin.posts,
  users: state.admin.users,
  reports: _.values(state.admin.posts_reports),
  next: state.admin.report_page_next,
  prev: state.admin.report_page_prev,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  updatePostStatus,
  getPostsReport,
  getUsers,
  getPosts,
})(UserPostsReport);
