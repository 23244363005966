/******************* 
@Purpose : Used for environment configuration
@Parameter : {API_URL, IMG_URL, PORT}
@Author : INIC
******************/
module.exports = {
  API_URL: process.env.REACT_APP_API_URL,
  IMG_URL: process.env.REACT_APP_IMG_URL,
  PUBLIC_FILE_URL: process.env.REACT_APP_PUBLIC_FILE_URL,
  PORT: process.env.REACT_APP_PORT,
  FIRBASE_CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
};
