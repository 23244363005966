import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { sidebaropen } from "../../store/Actions"; // Commun function
import * as _ from "lodash";
/******************* 
@Purpose : Used for sidebar view
@Parameter : props
@Author : INIC
******************/
function Sidebar(props) {
  const [, setUserName] = useState("");
  const [, setPhoto] = useState("");
  const [lang] = useTranslation("language");
  const [reportsMenu, openReportsMenu] = useState(false);
  const [settingsmenu, openSettingsmenu] = useState(false);
  const [contentmenu, openContentmenu] = useState(true);
  const [blogsmenu, openBlogsmenu] = useState(true);
  const [, openSocialmediamenu] = useState(true);
  const path = props.location.pathname;

  useEffect(() => {
    if (props.rolePermissions && !_.isEmpty(props.rolePermissions)) {
    
    }
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, photo } = props.admindata;
      setUserName(firstname + " " + lastname);
      setPhoto(photo);
    }
  }, [props]);

  function useOuterClick(callback) {
    const outerRef = useRef();
    const callbackRef = useRef();

    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      if (
        props.history.location.pathname === "/posts-report" ||
        props.history.location.pathname === "/user-report"
      ) {
        openReportsMenu(true);
      }
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (
          outerRef.current &&
          callbackRef.current &&
          !outerRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);
    return outerRef;
  }

  const innerRef = useOuterClick(() => {
  });
  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.remove("sidebar-open");
  };
  /******************* 
  @Purpose : Used for change routes
  @Parameter : e, page
  @Author : INIC
  ******************/
  const changeRoute = (e, page) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      page === "/dashboard" ||
      page === "/userManagement" ||
      page === "/cms" ||
      page === "/banners" ||
      page === "/master-placeholder"
    ) {
      openReportsMenu(false);
      openContentmenu(false);
      openSettingsmenu(false);
    }

    if (page === "/faq") {
      openBlogsmenu(false);
    }
    document.body.classList.remove("sidebar-open");
    return props.history.push(page);
  };
  /******************* 
  @Purpose : Used for blog handle
  @Parameter : e
  @Author : INIC
  ******************/
  const blogs = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    openContentmenu(true);
    openBlogsmenu(!blogsmenu);
  };
  /******************* 
  @Purpose : Used for user menu handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const user = () => {
    openReportsMenu(!reportsMenu);
    openContentmenu(false);
    openSettingsmenu(false);
    openBlogsmenu(false);
  };
  /******************* 
  @Purpose : Used for content handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const content = () => {
    openContentmenu(!contentmenu);
    openReportsMenu(false);
    openSettingsmenu(false);
    if (!contentmenu) {
      openBlogsmenu(false);
    }
  };
  /******************* 
  @Purpose : Used for settings handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const settings = () => {
    openSettingsmenu(!settingsmenu);
    openContentmenu(false);
    openReportsMenu(false);
    openBlogsmenu(false);
    if (!settingsmenu) {
      openSocialmediamenu(false);
    }
  };

  let isLogined = localStorage.getItem("accessToken");
  return (
    <div>
      {isLogined ? (
        <div ref={innerRef} id="container" className="App">
          <header className="App-header"></header>
          <aside className="sidebar">
            <div className="sidebar-inner-wrapper">
              <div className="sidebar-heading">
                <div className="d-flex align-items-end justify-content-between w-100">
                  <Link className="navbar-brand">
                    <div className="brand-logo">
                      Energym
                      {/* <img
                        className="img-fluid"
                        src="assets/images/brand-logo.svg"
                        alt="branding logo"
                      /> */}
                    </div>
                  </Link>
                  <span onClick={SidebarOpen} className="sidebar-close">
                    <em className="bx bx-x"></em>
                  </span>
                </div>
              </div>
              <PerfectScrollbar>
                <div className="sidebar-menu">
                  <ul className="sidebar-menu-list">
                    <li className="menu-list-item">
                      <a
                        className={
                          path === "/dashboard"
                            ? "menu-link active-link"
                            : "menu-link"
                        }
                        onClick={(e) => {
                          changeRoute(e, "/dashboard");
                        }}
                      >
                        <i className="bx bxs-home" />
                        <span className="menu-title">
                          {lang("CustomLabels.dashboard")}
                        </span>
                      </a>
                    </li>
                    <li className="menu-list-item">
                      <a
                        className={
                          path === "/usermanagement"
                            ? "menu-link active-link"
                            : "menu-link"
                        }
                        onClick={(e) => {
                          changeRoute(e, "/usermanagement");
                        }}
                      >
                        <i className="bx bxs-user" />
                        <span className="menu-title">
                          {lang("CustomLabels.users")}
                        </span>
                      </a>
                    </li>
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/cms");
                        }}
                        className={
                          path === "/cms"
                            ? "menu-link active-link"
                            : "menu-link"
                        }
                      >
                        <i className="bx bxs-note" />
                        <span className="menu-title">
                          {lang("CustomLabels.staticpages")}
                        </span>
                      </a>
                    </li>
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/banners");
                        }}
                        className={
                          path === "/banners"
                            ? "menu-link active-link"
                            : "menu-link"
                        }
                      >
                        <i className="bx bxs-image" />
                        <span className="menu-title">
                          {lang("CustomLabels.banners")}
                        </span>
                      </a>
                    </li>
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/master-placeholder");
                        }}
                        className={
                          path === "/master-placeholder"
                            ? "menu-link active-link"
                            : "menu-link"
                        }
                      >
                        <i className="bx bxs-message-alt-add" />
                        <span className="menu-title">
                          {lang("CustomLabels.masterPlaceholder")}
                        </span>
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        user();
                      }}
                      className={
                        reportsMenu
                          ? "menu-list-item has-child-submenu level1 submenu-open"
                          : "menu-list-item has-child-submenu level1"
                      }
                    >
                      <a className="menu-link">
                        <i className="bx bxs-report" />
                        <span className="menu-title">
                          {lang("CustomLabels.reports")}
                        </span>
                        <i className="bx bxs-chevron-right" />
                      </a>

                      <ul
                        className={
                          reportsMenu
                            ? "sidebar-menu-list sub-menu-list show"
                            : "sidebar-menu-list sub-menu-list"
                        }
                        style={
                          reportsMenu ? { display: "block" } : { display: "none" }
                        }
                      >
                        <li className="menu-list-item">
                          <a
                            onClickCapture={(evt) =>
                              changeRoute(evt, "/posts-report")
                            }
                            className={
                              path == "/posts-report"
                                ? "menu-link active-link"
                                : "menu-link"
                            }
                          >
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">
                              Post Report
                            </span>
                          </a>
                        </li>
                        <li className="menu-list-item">
                          <a
                            onClickCapture={(evt) =>
                              changeRoute(evt, "/user-report")
                            }
                            className={
                              path === "/user-report"
                                ? "menu-link active-link"
                                : "menu-link"
                            }
                          >
                            <i className="bx bxs-right-arrow-alt" />
                            <span className="menu-title">
                              User Report
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </PerfectScrollbar>
            </div>
          </aside>
        </div>
      ) : null}
    </div>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  admindata: state.admin.adminData,
  
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {sidebaropen })(
  withRouter(Sidebar)
);
