import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// Used for api call
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import _ from "lodash";
import Loader from "../../components/Loader/Loader"; // Used lading screen view
import Datetime from "react-datetime";

import Pagination from "rc-pagination"; // Used pagination
import { Select } from "antd";
import { showMessageNotification } from "../../utils/Functions"; // Utility functions
import {
  addBanner,
  getBanners,
  updateBanner,
  updateBannerStatus,
  deleteBanner,
} from "../../store/Actions";
import moment from "moment";
import CropImages from "../../components/CropImages/CropImages";
import { isURL } from "../../utils/Functions";
import debounce from "debounce";
import "react-datetime/css/react-datetime.css";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

const editBannerStatus = debounce((cb, id, status) => cb(id, status), 1000);
const { Option } = Select;
/******************* 
@Purpose : Used for blog category
@Parameter : props
@Author : INIC
******************/
function BlogCategory(props) {
  const [bannerList, setBannerList] = useState(props.banners);
  const [multipleDelete] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setIsFormValid] = useState("");
  const [show, setShow] = useState(false);
  const [, setEditCategory] = useState(false);
  const [editBanner, setEditBanner] = useState("");
  const [showEditOption, setShowEditOption] = useState(true);
  const [showDeleteOption, setShowDeleteOption] = useState(true);
  const [showAddOption, setShowAddOption] = useState(true);
  const [cropImage, setCropImage] = useState();
  const [postDate, setPostDate] = useState();
  const [refLink, setRefLink] = useState();
  const [errors, setErrors] = useState("");
  const [isFormSubmitting, setIsFormSumitting] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  useEffect(() => {
    props.getBanners((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    });
  }, []);

  useEffect(() => {
    setBannerList(props.banners);
  }, [props.banners]);

  /******************* 
  @Purpose : Reset Form Data
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFormData = () => {
    setCropImage("");
    setPostDate("");
    setRefLink("");
    setShow(false);
  };

  /******************* 
  @Purpose : Used for validate form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let formError = { cropImage: "", expiryDate: "", refLink: "" };
    let isFormValid = true;
    if (!cropImage || !cropImage.url)
      formError.cropImage = "*Please Select and Crop Image";
    else formError.cropImage = "";

    if (!postDate) formError.expiryDate = "*Expiry Date is required";
    else formError.expiryDate = "";

    if (!refLink) {
      formError.refLink = "*Reference Url is required";
    } else if (!isURL(refLink)) {
      formError.refLink = "*Please enter valid reference url";
    } else {
      formError.refLink = "";
    }

    if (
      formError.cropImage !== "" ||
      formError.expiryDate !== "" ||
      formError.refLink !== ""
    )
      isFormValid = false;

    setErrors(formError);
    setIsFormValid(isFormValid);

    return isFormValid;
  };
  /*
  @Purpose : Open Model With Banner Data Added
  @Parameter : id
  @Author : INIC
  ******************/
  const openModelEditMode = async (id, key) => {
    setPostDate(
      props.banners[key].expiry_date.seconds
        ? props.banners[key].expiry_date.toDate()
        : props.banners[key].expiry_date
    );
    setCropImage({
      blob: "",
      file: props.banners[key].banner_img,
    });
    setRefLink(props.banners[key].ref_link);
    setShow(true);
    setEditCategory(true);
    setIsFormSumitting(false);
  };
  /******************* 
  @Purpose : Used for delete blog catagory
  @Parameter : bId
  @Author : INIC
  ******************/
  const deleteCategoriesHandler = async (bannerLink, bId) => {
    var delArr = multipleDelete;
    delArr = _.compact(delArr);
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (delArr.length > 0) {
          var body = { ids: delArr };
        } else {
          props.deleteBanner(bannerLink, bId, (err) => {
            err
              ? showMessageNotification("Something went wrong", "error")
              : showMessageNotification(
                  "Successfully Deleted Banner",
                  "success"
                );
          });
        }
      }
    });
  };
  /******************* 
  @Purpose : Used for handle Banner status
  @Parameter : {status,index}
  @Author : INIC
  ******************/
  const bannerStatusHandler = (status, index) => {
    let newBannerList = [...bannerList];
    newBannerList[index].is_active = !status;
    setBannerList(newBannerList);
    editBannerStatus(
      props.updateBannerStatus,
      newBannerList[index].bannerid,
      !status
    );
  };
  /******************* 
  @Purpose : Used for close modal handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const modelCloseBtn = () => {
    setShow(false);
    setErrors("");
  };
  /******************* 
  @Purpose : Used for modal add category handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const modalAddCategory = () => {
    setShow(true);
    setEditBanner("");
    setIsFormSumitting(false);
  };
  /******************* 
  @Purpose : Used for modal update category handle
  @Parameter : e
  @Author : INIC
  ******************/
  const addBannerItem = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsFormSumitting(true);
      props.addBanner(
        cropImage.file,
        {
          banner_img: `banner/banner_${props.banners.length + 1}.png`,
          expiry_date: moment.utc(postDate).toDate(),
          ref_link: refLink,
          is_active: true,
        },
        () => {
          resetFormData();
        }
      );
    }
  };

  /******************* 
  @Purpose : Used for modal update category handle
  @Parameter : e
  @Author : INIC
  ******************/
  const updateBannerItem = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsFormSumitting(true);
      props.updateBanner(
        cropImage.file,
        {
          banner_img: `banner/banner_${editBanner.idx + 1}.png`,
          expiry_date: moment.utc(postDate).toDate(),
          ref_link: refLink,
          is_active: editBanner.is_active,
          bannerid: editBanner.bannerid,
        },
        () => {
          resetFormData();
        }
      );
    }
  };

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="content-header-title border-0">Banners List</li>
              </ol>
            </nav>
            <div className="content-area position-relative">
              <div className="grid">
                <div className="grid-content">
                  {/* filterbar buttons */}
                  <div className="d-flex align-items-center justify-content-between mb-2 left-buttons">
                    <div className="left-buttons d-flex align-items-center flex-wrap">
                      {showAddOption ? (
                        <Button
                          onClick={modalAddCategory}
                          id="addNewCategory"
                          type="button"
                          className="btn glow-primary btn-primary minW-0"
                        >
                          <i className="bx bx-user-plus d-lg-none" />
                          <span className="d-none d-sm-none d-lg-inline-block">
                            Add Banner
                          </span>
                        </Button>
                      ) : null}
                    </div>
                  </div>

                  {/* filter bar buttons end */}
                  {/* datatable start */}
                  <Modal show={show} size="xl" onHide={modelCloseBtn}>
                    <Modal.Header closeButton>
                      <div class="d-flex align-items-center">
                        <div class="icon mr-2">
                          <span class="bx bxs-plus-circle"></span>
                        </div>
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          {editBanner ? "Update Banner" : "Add Banner"}
                        </h5>
                      </div>
                    </Modal.Header>
                    <Modal.Body closeButton>
                      <div class="notification-form">
                        <div class="row">
                          <div class="col-md-12">
                            <CropImages
                              cropWindowWidth="500"
                              cropWindowHeight="300"
                              locked
                              onCrop={(url, blob) => {
                                setCropImage({
                                  url: url,
                                  file: blob,
                                });
                              }}
                            />
                            <div className="text-danger d-block">
                              {errors.cropImage}
                            </div>
                            <div>
                              {cropImage ? (
                                <img
                                  src={
                                    cropImage.file &&
                                    typeof cropImage.file !== "object"
                                      ? cropImage.file
                                      : cropImage.url
                                  }
                                />
                              ) : null}
                            </div>
                            <div className="form-group mb-md-5 mb-3 mt-2">
                              <label htmlFor="Title">
                                Expiry Date
                                <sup className="text-danger">*</sup>
                              </label>
                              <Datetime
                                className="d-block w-100"
                                inputProps={{
                                  placeholder: "Select date & time",
                                }}
                                timeFormat={"h:mm A"}
                                isValidDate={(currentDate) =>
                                  currentDate.isAfter(
                                    moment().clone().add(0, "days")
                                  )
                                }
                                value={postDate ? new Date(postDate) : ""}
                                onChange={(date) => setPostDate(date)}
                              />
                              <div className="text-danger d-block">
                                {errors.expiryDate}
                              </div>
                            </div>
                            <div className="form-group mb-md-5 mb-3">
                              <label htmlFor="Title">
                                Reference Link
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="reflink"
                                name="reflink"
                                placeholder="https://example.com"
                                value={refLink}
                                onChange={(e) => setRefLink(e.target.value)}
                              />
                              <span className="text-danger d-block">
                                {errors.refLink}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div class="modal-btn">
                              <Link
                                onClick={(e) =>
                                  editBanner
                                    ? updateBannerItem(e)
                                    : addBannerItem(e)
                                }
                                class={"btn btn-primary"}
                              >
                                {isFormSubmitting ? (
                                  <span className="spinner-border text-light spinner-border-sm mr-2"></span>
                                ) : null}
                                {editBanner ? "Update" : "Add"}{" "}
                              </Link>
                              <Link
                                onClick={resetFormData}
                                class="btn btn-link ml-2"
                              >
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div className="table-responsive">
                    <table
                      className="table row-border nowrap common-datatable"
                      id="content-blog-listing"
                    >
                      <thead>
                        <tr>
                          <th className="all" width="250">
                            <b>Banner</b>
                          </th>
                          <th className="all">
                            <b>Expiry Date</b>
                          </th>
                          <th className="all">
                            <b>Ref Link</b>
                          </th>
                          <th className="all">
                            <b>Status</b>
                          </th>
                          <th className="all">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {bannerList && bannerList.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              {loading && <Loader />}

                              {!loading ? (
                                <h6
                                  className="text-center text-danger not-found-txt"
                                  colSpan="6"
                                >
                                  No Records found!
                                </h6>
                              ) : null}
                            </td>
                          </tr>
                        ) : !loading ? (
                          bannerList &&
                          Array.isArray(bannerList) &&
                          bannerList.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <div
                                    className="thumb-img d-flex align-items-center position-relative cursor-pointer"
                                    style={{ minHeight: "100px" }}
                                    onClick={() => { setModalShow(true); setImageUrl(item.banner_img); }}
                                  >
                                    <span class="position-absolute img-loader spinner-grow spinner-grow-sm"></span>
                                    <img src={item.banner_img} width="150px" />
                                  </div>
                                </td>
                                <td>
                                  {moment(
                                    item.expiry_date.seconds
                                      ? item.expiry_date.toDate()
                                      : item.expiry_date
                                  ).format("DD/MM/YYYY")}
                                </td>
                                <td>{item.ref_link}</td>
                                <td>
                                  <div className="custom-control custom-switch light">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={item.bannerid}
                                      checked={
                                        item.is_active ? item.is_active : false
                                      }
                                      onChange={() => {
                                        bannerStatusHandler(
                                          item.is_active,
                                          key
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for={item.bannerid}
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    {showEditOption && (
                                      <Link className="cursor-pointer mr-3">
                                        <i
                                          class="bx bx-edit"
                                          onClick={() => {
                                            openModelEditMode(
                                              item.bannerid,
                                              key
                                            );
                                            setEditBanner({
                                              ...item,
                                              idx: key,
                                            });
                                          }}
                                        />
                                      </Link>
                                    )}
                                    {showDeleteOption && (
                                      <Link className="cursor-pointer mr-3">
                                        <i
                                          className="bx bx-trash-alt"
                                          onClick={() =>
                                            deleteCategoriesHandler(
                                              item.banner_img,
                                              item.bannerid
                                            )
                                          }
                                        />
                                      </Link>
                                    )}
                                    <a
                                      href={item.banner_img}
                                      target="_blank"
                                      download
                                    >
                                      <i className="bx bx-show"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <Loader />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="modal-90w"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Body>
          <img src={imageUrl} />
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  banners: _.values(state.admin.banners),
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  getBanners,
  addBanner,
  updateBanner,
  updateBannerStatus,
  deleteBanner,
})(BlogCategory);
