import React, { useState, useEffect } from "react";

// Used for api call
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../../components/Layout/Layout";
import _ from "lodash";
import swal from "sweetalert";
import { getPosts, updatePostStatus } from "../../store/Actions";
import { Modal } from "react-bootstrap";
/******************* 
@Purpose : Used for view user details
@Parameter : props
@Author : INIC
******************/
function EditPost(props) {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(props.posts[props.match.params.slug]);
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    props.getPosts((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    });
  }, []);
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">Post</li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <Link
                        onClick={() =>
                          props.history.push("/user-posts-report")
                        }
                      >
                        Reports
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      View Post
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div class="card profile-card mb-5">
                    {!loading ? (
                      post ? (
                        <div className="row">
                          <div className="col-lg-12 col-xl-3">
                            <div className="edit-image">
                              <div
                                className="user-image cursor-pointer"
                                onClick={() => setModalShow(true)}
                              >
                                <img src={post.attachment} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-9">
                            <div className="user-title-info user-details">
                              <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                <label className="mb-0" htmlFor="firstName">
                                  Title
                                </label>
                                <div className="w-100 text-readonly">
                                  <span>{post.title}</span>
                                </div>
                              </div>
                            </div>
                            <div className="user-title-info user-details">
                              <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                <label className="mb-0" htmlFor="firstName">
                                  User
                                </label>
                                <div className="w-100 text-readonly">
                                  <span>{post.user_full_name}</span>
                                </div>
                              </div>
                            </div>
                            <div className="user-title-info user-details">
                              <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                <label className="mb-0" htmlFor="firstName">
                                  Mobile
                                </label>
                                <div className="w-100 text-readonly">
                                  <span>{post.user_mobile_number}</span>
                                </div>
                              </div>
                            </div>
                            <div className="user-title-info user-details">
                              <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                <label className="mb-0" htmlFor="firstName">
                                  Status
                                </label>
                                <div className="w-100 text-readonly">
                                  <div className="custom-control custom-switch light">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={post.userid}
                                      checked={!post.is_active ? false : true}
                                      onChange={() => {
                                        setPost({
                                          ...post,
                                          is_active: !post.is_active,
                                        });
                                        props.updatePostStatus(
                                          props.match.params.slug,
                                          !post.is_active
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for={post.userid}
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        "No Record Found"
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © Energym</span>
            </div>
          </footer>
        </div>
        <Modal
          dialogClassName="modal-90w"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Body>
            <img src={post?.attachment} />
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => {
  return {
    posts: state.admin.posts,
    next: state.admin.post_page_next,
    prev: state.admin.post_page_prev,
  };
};
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { getPosts, updatePostStatus })(
  EditPost
);
