import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// Used for api call
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import _ from "lodash";
import Loader from "../../components/Loader/Loader"; // Used lading screen view
import Datetime from "react-datetime";

import Pagination from "rc-pagination"; // Used pagination
import { Select } from "antd";
import { showMessageNotification } from "../../utils/Functions"; // Utility functions
import {
  addPlaceholder,
  getPlaceholders,
  updatePlaceholder,
  deletePlaceholder,
} from "../../store/Actions/masterPlaceholder";
import moment from "moment";
import CropImages from "../../components/CropImages/CropImages";
import { isURL } from "../../utils/Functions";
import debounce from "debounce";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

const { Option } = Select;
/******************* 
@Purpose : Used for blog category
@Parameter : props
@Author : INIC
******************/
function BlogCategory(props) {
  const [lang] = useTranslation("language");
  const [blogCategoryTitle, setBlogCategoryTitle] = useState("");
  const [blogCategoryDescription, setBlogCategoryDescription] = useState("");
  const [, setCategoryStatus] = useState(false);
  const [placholderList, setPlaceholderList] = useState(props.placeholders);
  const [createdById, setCreatedById] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [, setColumnSettingsArr] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(1);
  const [multipleDelete] = useState([]);
  const [condition] = useState("");
  const [loading, setLoading] = useState(true);
  const [, setBlogId] = useState("");
  const [, setIsFormValid] = useState("");
  const [show, setShow] = useState(false);
  const [, setEditCategory] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [editPlaceholder, setEditPlaceholder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState([]);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [allBlogStatus, setAllBlogStatus] = useState([]);
  const [length, setLength] = useState(1);
  const [showEditOption, setShowEditOption] = useState(true);
  const [showDeleteOption, setShowDeleteOption] = useState(true);
  const [showAddOption, setShowAddOption] = useState(true);
  const [cropImage, setCropImage] = useState();
  const [postDate, setPostDate] = useState();
  const [refLink, setRefLink] = useState();
  let [errors, setErrors] = useState("");
  const [isFormSubmitting, setIsFormSumitting] = useState(false);

  useEffect(() => {
    props.getPlaceholders((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    });
  }, []);

  useEffect(() => {
    setPlaceholderList(props.placeholders);
  }, [props.placeholders]);

  /******************* 
  @Purpose : Reset Form Data
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFormData = () => {
    setCropImage("");
    setPostDate("");
    setRefLink("");
    setShow(false);
    setIsCheckBoxSelected(false);
  };

  /******************* 
  @Purpose : Used for get all blog category
  @Parameter : {}
  @Author : INIC
  ******************/
  const getAllBlogCategory = async () => {
    let body = {
      page,
      pagesize,
      columnKey: "placholderList",
      condition: condition,
    };
  };
  /******************* 
  @Purpose : Used for validate form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let formError = { cropImage: "", expiryDate: "", refLink: "" };
    let isFormValid = true;
    if (!cropImage || !cropImage.url)
      formError.cropImage = "*Please Select and Crop Image";
    else formError.cropImage = "";

    if (formError.cropImage !== "") isFormValid = false;

    setErrors(formError);
    setIsFormValid(isFormValid);

    return isFormValid;
  };
  /*
  @Purpose : Open Model With Banner Data Added
  @Parameter : id
  @Author : INIC
  ******************/
  const openModelEditMode = async (id, key) => {
    setCropImage({
      blob: "",
      file: props.placeholders[key].place_holder,
    });
    setShow(true);
    setEditCategory(true);
    setIsFormSumitting(false);
  };
  
  /******************* 
  @Purpose : Used for delete blog catagory
  @Parameter : bId
  @Author : INIC
  ******************/
  const deleteCategoriesHandler = async (placeholderLink, bId) => {
    var delArr = multipleDelete;
    delArr = _.compact(delArr);
    swal({
      title: "Are you sure, you want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (delArr.length > 0) {
          var body = { ids: delArr };
        } else {
          props.deletePlaceholder(placeholderLink, bId, (err) => {
            err
              ? showMessageNotification("Something went wrong", "error")
              : showMessageNotification(
                  "Successfully Deleted Banner",
                  "success"
                );
          });
        }
      }
    });
  };

  /******************* 
  @Purpose : Used for close modal handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const modelCloseBtn = () => {
    setShow(false);
    setErrors("");
  };
  /******************* 
  @Purpose : Used for modal add category handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const modalAddCategory = () => {
    setShow(true);
    setBlogCategoryTitle("");
    setBlogCategoryDescription("");
    setEditPlaceholder("");
    setIsFormSumitting(false);
  };
  /******************* 
  @Purpose : Used for modal update category handle
  @Parameter : e
  @Author : INIC
  ******************/
  const addPlaceholderItem = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsFormSumitting(true);
      props.addPlaceholder(
        cropImage.file,
        {
          place_holder_img: `user_placeholder/place_holder_${
            props.placeholders.length + 1
          }.png`,
        },
        () => {
          resetFormData();
        }
      );
    }
  };

  /******************* 
  @Purpose : Used for modal update category handle
  @Parameter : e
  @Author : INIC
  ******************/
  const updatePlaceholderItem = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsFormSumitting(true);
      props.updatePlaceholder(
        cropImage.file,
        {
          place_holder_img: `user_placeholder/place_holder_${
            props.placeholders.length + 1
          }.png`,
          placeholderid: editPlaceholder.placeholderid,
        },
        () => {
          resetFormData();
        }
      );
    }
  };

  // /*******************
  // @Purpose : Used for multiple checkbox selection
  // @Parameter : e
  // @Author : INIC
  // ******************/
  // const handleChangeCheckbox = (e) => {
  //   let tempListing = [...placholderList];
  //   let checkedAll = allChecked;

  //   if (e.target.value === "checkAll") {
  //     tempListing.forEach((item) => {
  //       item.isChecked = e.target.checked;
  //       checkedAll = e.target.checked;
  //     });
  //   } else {
  //     tempListing.find(({ _id }) => _id === e.target.value).isChecked =
  //       e.target.checked;
  //     let newList = tempListing.filter((el) => el.isChecked === false);
  //     if (newList.length <= 0) {
  //       checkedAll = true;
  //     } else {
  //       checkedAll = false;
  //     }
  //   }
  //   setPlaceholderList(tempListing);
  //   setAllChecked(checkedAll);
  //   setIsCheckBoxSelected(placholderList.filter((el) => el.isChecked === true));
  // };
  /******************* 
  @Purpose : Used for multiple checkbox selection & delete records
  @Parameter : {}
  @Author : INIC
  ******************/
  const deleteRecords = () => {
    let lists = placholderList
      .map((el) => (el.isChecked === true ? el._id : ""))
      .filter((el) => el !== "");

    if (lists.length > 0) {
      swal({
        title: "Are you sure, you want to delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let body;
          body = { ids: lists };
        }
      });
    }
  };
  /******************* 
  @Purpose : Used for bulk status change
  @Parameter : {}
  @Author : INIC
  ******************/
  const bulkStatusChange = async () => {
    let newList = allBlogStatus.filter(({ isChecked }) => isChecked);
    newList.forEach((blog) => (blog.status = !blog.status));

    if (allBlogStatus.length > 0) {
      showMessageNotification("Status updated successfully", "success");
    }
  };
  /******************* 
  @Purpose : Used for toggle status change
  @Parameter : {}
  @Author : INIC
  ******************/
  const toggleStatusHandler = () => {
    setToggleStatus(!toggleStatus);
    let tempList = placholderList;
    setAllBlogStatus(tempList);
    bulkStatusChange();
  };
  /******************* 
  @Purpose : Used for blog pagination change
  @Parameter : {}
  @Author : INIC
  ******************/
  const blogPagination = (pageNo, pageSize) => {
    setPage(pageNo);
    setPagesize(pageSize);
  };

  const numbers = [2, 5, 10, 15, 25, 50, 100, 200, 500].filter(
    (number) => number < total
  );
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="content-header-title border-0">
                  Master User Placeholder List
                </li>
              </ol>
            </nav>
            <div className="content-area position-relative">
              <div className="grid">
                <div className="grid-content">
                  {/* filterbar buttons */}
                  <div className="d-flex align-items-center justify-content-between mb-2 left-buttons">
                    <div className="left-buttons d-flex align-items-center flex-wrap">
                      {showAddOption ? (
                        <Button
                          onClick={modalAddCategory}
                          id="addNewCategory"
                          type="button"
                          className="btn glow-primary btn-primary minW-0"
                        >
                          <i className="bx bx-user-plus d-lg-none" />
                          <span className="d-none d-sm-none d-lg-inline-block">
                            Add Placeholder Image
                          </span>
                        </Button>
                      ) : null}
                    </div>
                  </div>

                  {/* filter bar buttons end */}
                  {/* datatable start */}
                  <Modal show={show} size="xl" onHide={modelCloseBtn}>
                    <Modal.Header closeButton>
                      <div class="d-flex align-items-center">
                        <div class="icon mr-2">
                          <span class="bx bxs-plus-circle"></span>
                        </div>
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          {editPlaceholder
                            ? "Update Placeholder"
                            : "Add Placholder"}
                        </h5>
                      </div>
                    </Modal.Header>
                    <Modal.Body closeButton>
                      <div class="notification-form">
                        <div class="row">
                          <div class="col-md-12">
                            <CropImages
                              cropWindowWidth="300"
                              cropWindowHeight="300"
                              locked
                              onCrop={(url, blob) => {
                                setCropImage({
                                  url: url,
                                  file: blob,
                                });
                              }}
                            />
                            <div className="text-danger d-block">
                              {errors.cropImage}
                            </div>
                            <div>
                              {cropImage ? (
                                <img
                                  src={
                                    cropImage.file &&
                                    typeof cropImage.file !== "object"
                                      ? cropImage.file
                                      : cropImage.url
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div class="modal-btn">
                              <Link
                                onClick={(e) =>
                                  editPlaceholder
                                    ? updatePlaceholderItem(e)
                                    : addPlaceholderItem(e)
                                }
                                class={"btn btn-primary"}
                              >
                                {isFormSubmitting ? (
                                  <span className="spinner-border text-light spinner-border-sm mr-2"></span>
                                ) : null}
                                {editPlaceholder ? "Update" : "Add"}
                              </Link>
                              <Link
                                onClick={resetFormData}
                                class="btn btn-link ml-2"
                              >
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div className="row">
                    {!loading ? (
                      placholderList &&
                      Array.isArray(placholderList) &&
                      placholderList.map((item, key) => {
                        return (
                          <div className="col-2" key={key}>
                            <div class="card justify-content-center pt-3 pl-3 pr-3 pb-4">
                              <div className="d-flex justify-content-end mb-4">
                                {showEditOption && (
                                  <Link className="cursor-pointer mr-3">
                                    <i
                                      class="bx bx-edit"
                                      onClick={() => {
                                        openModelEditMode(
                                          item.placeholderid,
                                          key
                                        );
                                        setEditPlaceholder({
                                          ...item,
                                          idx: key,
                                        });
                                      }}
                                    />
                                  </Link>
                                )}
                                {showDeleteOption && (
                                  <Link className="cursor-pointer mr-3">
                                    <i
                                      className="bx bx-trash-alt"
                                      onClick={() =>
                                        deleteCategoriesHandler(
                                          item.place_holder,
                                          item.placeholderid
                                        )
                                      }
                                    />
                                  </Link>
                                )}
                                <a
                                  href={item.place_holder}
                                  className="cursor-pointer"
                                  target="_blank"
                                  download
                                >
                                  <i className="bx bx-show"></i>
                                </a>
                              </div>

                              <div
                                className="d-flex justify-content-center"
                                style={{ minHeight: "80px" }}
                              >
                                <img src={item.place_holder} width="80px" />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  placeholders: _.values(state.admin.placeholders),
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  addPlaceholder,
  getPlaceholders,
  updatePlaceholder,
  deletePlaceholder,
})(BlogCategory);
