import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { getUsers } from "../../store/Actions";
import { showMessageNotification } from "../../utils/Functions";

/******************* 
@Purpose : Used for dashboard view
@Parameter : {}
@Author : INIC
******************/
function Dashboard(props) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.getUsers((err) => {
      if (err) showMessageNotification("Somehting Went Wrong", "error");
      setLoading(false);
    });
  }, []);

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            {loading ? (
              <Loader />
            ) : (
              <div className="dashboard-item">
                <Link to="/usermanagement">
                  <div className="dashboard-item-wrapper">
                    <h2>
                      <i className="bx bxs-user" /> Users
                    </h2>
                    <div className="count">{props.users.length}</div>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between"></div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  users: _.values(state.admin.users),
});
export default connect(mapStateToProps, { getUsers })(Dashboard);
