import { useState, useEffect } from "react";
import db, { fireStorage, fireStorageRef } from "../../Firebase/base";
import { setItem, showMessageNotification } from "./../../utils/Functions"; // Utility dunctions
// Default Value
const languageKey = "language";
const themeKey = "theme";
const resizeKey = "resize";
const sidebarKey = "sidebar";

/******************* 
@Purpose : Get User Placeholder List
@Parameters : {}
@Author : INIC
******************/
export const getPlaceholders = (callback) => (dispatch) => {
  let payload = [];
  db.collection("master_user_placeholder")
    .get()
    .then((doc) => {
      doc.forEach((item) => {
        payload = {
          ...payload,
          [item.id]: {
            ...item.data(),
            placeholderid: item.id,
          },
        };
      });
      dispatch({ type: "GET_PLACEHOLDERS", payload: payload });
      callback();
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

/******************* 
@Purpose : Add Placeholder
@Parameters : {}
@Author : INIC
******************/
export const addPlaceholder = (img, payload, callback) => (dispatch) => {
  fireStorageRef
    .child(payload.place_holder_img)
    .put(img)
    .then((snapshot) => {
      fireStorageRef
        .child(payload.place_holder_img)
        .put(img)
        .snapshot.ref.getDownloadURL()
        .then((downloadURL) => {
          db.collection("master_user_placeholder")
            .add({
              place_holder: downloadURL,
            })
            .then((docref) => {
              dispatch({
                type: "ADD_EDIT_PLACEHOLDER",
                payload: {
                  placeholderid: docref.id,
                  place_holder: downloadURL,
                },
              });
              callback();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        })
        .catch((err) => {
          callback(err);
        });
    })
    .catch((err) => {
      callback(err);
    });
};

/******************* 
@Purpose : Update Placeholder
@Parameters : {}
@Author : INIC
******************/
export const updatePlaceholder = (img, payload, callback) => (dispatch) => {
  fireStorageRef
    .child(payload.place_holder_img)
    .put(img)
    .then((snapshot) => {
      fireStorageRef
        .child(payload.place_holder_img)
        .put(img)
        .snapshot.ref.getDownloadURL()
        .then((downloadURL) => {
          db.collection("master_user_placeholder")
            .doc(payload.placeholderid)
            .update({
              place_holder: downloadURL,
            })
            .then((docref) => {
              dispatch({
                type: "ADD_EDIT_PLACEHOLDER",
                payload: {
                  placeholderid: payload.placeholderid,
                  place_holder: downloadURL,
                },
              });
              callback();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        })
        .catch((err) => {
          callback(err);
        });
    })
    .catch((err) => {
      callback(err);
    });
};
/******************* 
@Purpose : Delete Placeholder
@Parameters : {}
@Author : INIC
******************/
export const deletePlaceholder = (imgLink, id, callback) => (dispatch) => {
  fireStorage
    .refFromURL(imgLink)
    .delete()
    .then(() => {
      db.collection("master_user_placeholder")
        .doc(id)
        .delete()
        .then(() => {
          dispatch({
            type: "DELETE_PLACEHOLDER",
            payload: {
              placeholderid: id,
            },
          });
          callback();
        })
        .catch((err) => {
          callback(err);
        });
    })
    .catch((err) => {
      callback(err);
    });
};