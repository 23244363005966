import { Select } from "antd";
import "antd/dist/antd.css";
import debounce from "debounce";
import $ from "jquery";
import _ from "lodash";
import "rc-pagination/assets/index.css";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
// Used for api call
import swal from "sweetalert";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { getPosts, getUsers, updatePostStatus } from "../../store/Actions";
import { showModalNotification } from "../../utils/Functions";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

const editUserStatus = debounce((cb, id, status) => cb(id, status), 1000);
var { Option } = Select;
/******************* 
@Purpose : Used for users list view
@Parameter : props
@Author : INIC
******************/
function UserPosts(props) {
  const [columnSettings, setColumnSettings] = useState({
    photo: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobile: true,
    emailVerificationStatus: true,
    status: true,
  });
  const [postsList, setPostsList] = useState(props.posts);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    /******************* 
    @Purpose : Get Request for Post list
    @Parameter : Callback function
    @Author : INIC
    ******************/
    props.getPosts((err) => {
      if (err)
        showModalNotification(err.message || "something went wrong", "danger");
      /******************* 
        @Purpose : Get Request for User list
        @Parameter : Callback function
        @Author : INIC
        ******************/
      props.getUsers((err) => {
        setLoading(false);
        if (err)
          showModalNotification(
            err.message || "something went wrong",
            "danger"
          );
      });
    });
  }, []);

  useEffect(() => {
    setPostsList(props.posts);
  }, [props.posts]);

  /******************* 
  @Purpose : Used for pagination
  @Parameter : Pagination Type: Prev or Next
  @Author : INIC
  ******************/
  const paginationChange = (type) => {
    setLoading(true);
    props.getPosts(
      (err) => {
        if (err) {
          showModalNotification("Something Went Wrong", "error");
        }
        setLoading(false);
      },
      "",
      (type = "next" ? props.next : props.prev)
    );
  };

  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = debounce((keyword) => {
    setLoading(true);
    props.getPosts((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    }, keyword);
  }, 1000);

  /******************* 
  @Purpose : Used for handle UserStatus status
  @Parameter : {status,index}
  @Author : INIC
  ******************/
  const postStatusHandler = (status, index) => {
    let newPostList = [...postsList];
    newPostList[index].is_active = !status;
    setPostsList(newPostList);
    editUserStatus(props.updatePostStatus, newPostList[index].userid, !status);
  };

  /******************* 
  @Purpose : Open Image Modal
  @Parameter : {url}
  @Author : INIC
  ******************/
  const openImageModal = (url) => {
    setImageUrl(url);
    setModalShow(true);
  };
  let { photo } = columnSettings;
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block overflow-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="content-header-title border-0 ">Users</li>
              </ol>
            </nav>
            <div class="content-area position-relative">
              <div class="grid">
                <div class="grid-content">
                  <div class="user-listing-filterOptions mb-2 d-block">
                    <div class="row mb-2">
                      <div class="col-sm-8 position-static">
                        <div class="left-buttons d-flex ">
                          <label>
                            <div className="search-input-wrapper position-relative">
                              <i className="bx bx-search"></i>{" "}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="search"
                                onChange={(evt) =>
                                  searchField(evt.target.value)
                                }
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? <Loader /> : null}
                </div>
              </div>
            </div>
            {!loading ? (
              <div className="table-responsive">
                <table
                  class="table row-border nowrap common-datatable"
                  id="user-listing"
                >
                  <thead>
                    <tr>
                      {photo ? (
                        <th>
                          <b>Post Image</b>
                        </th>
                      ) : null}
                      <th>
                        <b>Title</b>
                      </th>
                      <th>
                        <b>User</b>
                      </th>
                      <th>
                        <b>Mobile</b>
                      </th>
                      <th>
                        <b>Status</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {postsList && postsList.length === 0 ? (
                      <tr className="text-center text-danger not-found-txt">
                        <td colSpan="6">
                          {!loading ? (
                            <h6
                              className="text-center text-danger not-found-txt"
                              colSpan="6"
                            >
                              No Records Found!
                            </h6>
                          ) : null}
                        </td>
                      </tr>
                    ) : (
                      postsList &&
                      Array.isArray(postsList) &&
                      postsList.map((post, key) => {
                        return (
                          <tr>
                            {photo ? (
                              <td>
                                <div
                                  className="thumb-img position-relative cursor-pointer"
                                  onClick={() => {
                                    openImageModal(post.attachment);
                                  }}
                                >
                                  <span class="position-absolute img-loader spinner-grow spinner-grow-sm"></span>
                                  {post.attachment && post.attachment !== "" ? (
                                    <img
                                      src={
                                        post.attachment
                                          ? post.attachment
                                          : "/assets/images/no-image-user.png"
                                      }
                                      alt="/assets/images/no-image-user.png"
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/images/no-user.png"}
                                      alt="/assets/images/no-image-user.png"
                                    />
                                  )}
                                </div>
                              </td>
                            ) : null}
                            <td>{post.title}</td>
                            <td>{post.user_full_name}</td>
                            <td>
                              {props.users[post.user_id]
                                ? props.users[post.user_id].country_code
                                : null}
                              {props.users[post.user_id]
                                ? props.users[post.user_id].mobile_number
                                : "Not Available"}
                            </td>
                            <td>
                              <div className="custom-control custom-switch light">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={post.document_id}
                                  checked={!post.is_active ? false : true}
                                  onChange={() =>
                                    postStatusHandler(post.is_active, key)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  for={post.document_id}
                                ></label>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            ) : null}
            {!loading ? (
              <div className="d-flex mt-3">
                {props.prev ? (
                  <Button
                    size="sm"
                    className="mr-2"
                    onClick={() => paginationChange("prev")}
                  >
                    Previous
                  </Button>
                ) : null}
                {props.next ? (
                  <Button size="sm" onClick={() => paginationChange("next")}>
                    Next
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between"></div>
          </footer>
        </div>
        <Modal
          dialogClassName="modal-90w"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Body>
            <img src={imageUrl} />
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  posts: _.values(state.admin.posts),
  users: state.admin.users,
  next: state.admin.post_page_next,
  prev: state.admin.post_page_prev,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  updatePostStatus,
  getPosts,
  getUsers,
})(UserPosts);
