import React , {Suspense}from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import {  getItem } from "./utils/Functions";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// Authentication pages
import AdminLogin from "./pages/Authentication/AdminLogin";

// Dashboard page
// Profile page
// Users page
import UsersList from "./pages/Users/UsersList";
import ViewuserDetails from "./pages/Users/ViewuserDetails";
// Contents page
import MasterCms from "./pages/Contents/MasterCms";
import EditMasterCms from "./pages/Contents/EditMasterCms";
import Banners from "./pages/Contents/Banners";
// EmailsTemplates page
// Master page
// RoleAccess page
// Settings page
// Transaction page
// Media page
// Support page
// 404 page
import FourZeroFour from "./pages/FourZeroFour/FourZeroFour";
// Offline page
import OfflinePage from "./pages/Offline/Offline";

// components
import Sidebar from "./components/Layout/SideBar";
import { Offline, Online } from "react-detect-offline";
//notification library
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// customized design intigration
import "./scss/styles.scss";
import MasterPlaceholder from "./pages/Contents/MasterPlaceholder";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserPosts from "./pages/ReportManagement/PostReport";
import UserPostReport from "./pages/ReportManagement/UserReport";
import EditPost from "./pages/ReportManagement/EditPost";

/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/
function App() {
  /******************* 
  @Purpose : Used for token authorization
  @Parameter : {}
  @Author : INIC
  ******************/
  const Authorization = () => {
    return getItem("accessToken") ? true : false;
  };
  /******************* 
  @Purpose : Used for private routing
  @Parameter : {Component1, ...rest}
  @Author : INIC
  ******************/
  const PrivateRoute = ({ component: Component1, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? <Component1 {...props} /> : <Redirect to="/" />
      }
    />
  );
  /******************* 
  @Purpose : Used for check user is already login or not
  @Parameter : {Component2, ...rest }
  @Author : INIC
  ******************/
  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component2 {...props} />
        )
      }
    />
  );
  /******************* 
  @Purpose : Used for check reset password
  @Parameter : {Component3, ...rest}
  @Author : INIC
  ******************/
  const ResetPasswordCheckRoute = ({ component: Component3, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component3 {...props} />
        )
      }
    />
  );
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Offline>
          <OfflinePage />
        </Offline>
        <Online>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <Sidebar />
              <div style={{ textAlign: "center" }}>
                <ToastContainer />
              </div>
              <Switch>
                <LoginCheckRoute exact path="/" component={AdminLogin} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute
                  exact
                  path="/usermanagement"
                  component={UsersList}
                />
                <PrivateRoute
                  exact
                  path="/userdetails/:slug"
                  component={ViewuserDetails}
                />
                <PrivateRoute exact path="/cms" component={MasterCms} />
                <PrivateRoute
                  exact
                  path="/cms/:slug"
                  component={EditMasterCms}
                />
                <PrivateRoute exact path="/banners" component={Banners} />
                <PrivateRoute
                  exact
                  path="/master-placeholder"
                  component={MasterPlaceholder}
                />
                <PrivateRoute exact path="/posts-report" component={UserPosts} />
                <PrivateRoute
                  exact
                  path="/user-report"
                  component={UserPostReport}
                />
                <PrivateRoute
                  exact
                  path="/edit-user-post/:slug"
                  component={EditPost}
                />
                <PrivateRoute path="/*" component={FourZeroFour} />
              </Switch>
            </Router>
          </PersistGate>
        </Online>
      </Provider>
    </Suspense>
  );
}

export default App;
