import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// Used for api call
import { Link } from "react-router-dom";

import _ from "lodash";
import StaticEditorEdit from "../../components/Editor/BlogEditorEdit";
import { showMessageNotification } from "../../utils/Functions"; //Utility functions
import { updateMasterCms } from "../../store/Actions";

/******************* 
@Purpose : Used for static CMS pages
@Parameter : props
@Author : INIC
******************/
function StaticCMS(props) {
  const [lang] = useTranslation("language");
  const editorRef = useRef();
  const [caption, setCaption] = useState();
  const [, setIsFormValid] = useState(true);
  const [pageTitle, setPageTitle] = useState("");
  let [errors, setErrors] = useState({});
  const pageTitleTrackChanges = useRef(null);

  useEffect(() => {
    setCaption(props.cmsDetails.value);
    setPageTitle(props.cmsDetails.type);
    // fetchBlogCategories();
  }, []);

  /******************* 
  @Purpose : Used for page title change
  @Parameter : e
  @Author : INIC
  ******************/
  const handlePageTitleChange = (e) => {
    let maxChar = 70;
    pageTitleTrackChanges.current.textContent = `Maximum ${
      maxChar - e.target.value.length
    } characters is suitable`;
    setPageTitle(e.target.value);
    errors = Object.assign(errors, { pageTitle: "" });
    setErrors(errors);
  };
  
  
  /******************* 
  @Purpose : Used for validate form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let error = {
      pageTitle: "",
      caption: "",
    };
    let isFormValid = true;

    if (!pageTitle.trim()) error.pageTitle = "*Page title is required";
    else if (pageTitle.length > 70)
      error.pageTitle = "*Page title can't be more than 70 characters";
    else error.pageTitle = "";

    if (!caption.trim()) error.caption = "*Content is required";
    else if (caption.length < 50)
      error.caption = "*Caption must contain minimum 50 characters";
    else error.caption = "";

    if (error.pageTitle !== "" || error.caption !== "") isFormValid = false;

    setErrors(error);
    setIsFormValid(isFormValid);

    return isFormValid;
  };

  /******************* 
  @Purpose : Used for Update Static Page
  @Parameter : e
  @Author : INIC
  ******************/
  const updateCmsPage = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updateCallback = (err) => {
        if (err) {
          showMessageNotification("Something Went Wrong", "failure");
        } else {
          showMessageNotification("Cms Updated SuccessFully", "success");
        }
      };
      props.updateMasterCms({
        id: props.cmsDetails.cmsid,
        data: {
          type: pageTitle,
          value: caption,
        },
        callback: updateCallback,
      });
    }
  };
  /******************* 
  @Purpose : Used for preview
  @Parameter : content
  @Author : INIC
  ******************/
  const getpreview = (content) => {
    setCaption(content);
  };

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="content-header-title">Edit Page</li>
                <li className="breadcrumb-item">
                  <Link
                    onClick={() => {
                      props.history.push("/cms");
                    }}
                  >
                    Master Cms
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Page
                </li>
              </ol>
            </nav>
            <div className="d-flex align-items-center justify-content-end mb-2 flex-wrap">
              <span className="author d-lg-none d-flex">
                {lang("ContentManagement.CMS.Author")}
              </span>
            </div>
            <div className="blog-content-block">
              <div className="card card-media">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-12">
                    <div className="form-group mb-md-5 mb-3">
                      <label htmlFor="pageTitle">Page Name</label>
                      <h4 className="text-capitalize">{pageTitle}</h4>
                    </div>
                  </div>
                </div>
                <div className="content-img-block">
                  <div className="img-drag-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label htmlFor="caption">
                            {lang("ContentManagement.CMS.content")}
                            <sup className="text-danger">*</sup>
                          </label>

                          <StaticEditorEdit
                            className="form-control mx-auto"
                            editContent={props.cmsDetails.value}
                            ref={editorRef}
                            getPreview={getpreview}
                          />
                          <span className="text-danger d-block">
                            {errors.caption}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <div className="form-group">
                    <div className="button-group-container">
                      <a
                        className="btn btn-primary mr-3 mb-sm-0 mb-2"
                        onClick={(e) => {
                          updateCmsPage(e);
                        }}
                      >
                        <span>Update</span>
                      </a>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          props.history.push("/cms");
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state, ownProps) => ({
  language: state.admin.language,
  cmsDetails: state.admin.cms[ownProps.match.params.slug],
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { updateMasterCms })(StaticCMS);
