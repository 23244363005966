import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Used for api call
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import CropImages from "../../components/CropImages/CropImages";
import { IMG_URL } from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../../components/Layout/Layout";
import moment from "moment";
/******************* 
@Purpose : Used for view user details
@Parameter : props
@Author : INIC
******************/
function ViewuserDetails(props) {
  const { details } = props;
  const [lang] = useTranslation("language");
  const [formData, setFormData] = useState({
    firstName: details.full_name.split(" ")[0],
    lastName: details.full_name.split(" ")[1],
    email: details.email,
    dob: moment(details.birth_date).format("MMMM Do YYYY"),
    mobile: details.country_code + details.mobile_number,
    address: details.address,
    wight: details.wight ? details.wight + "" + details.wight_type : null,
    height: details.height ? details.height + details.height_type : null,
    photo: details.profile_photo,
    activity_goal: details.activity_goal,
    active_minute_goal: details.active_minute_goal,
    activity_level: details.activity_level,
    energy_generate_goal: details.energy_generate_goal,
    errors: {},
  });
  const [, setUserId] = useState("");
  let {
    firstName,
    lastName,
    email,
    mobile,
    dob,
    address,
    wight,
    height,
    photo,
    errors,
    activity_goal,
    active_minute_goal,
    activity_level,
    energy_generate_goal,
  } = formData;
  let imagePreview = null;
  if (photo) {
    imagePreview = <img src={photo} alt="Icon" />;
  } else {
    imagePreview = <img src={"/assets/images/no-user.png"} alt="Icon" />;
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="content-header-title">
                      {lang("Usermanagement.edituser.labels.userprofile")}
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <Link
                        onClick={() => props.history.push("/usermanagement")}
                      >
                        users
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {lang("Usermanagement.edituser.labels.userprofile")}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div class="card profile-card mb-5">
                    <div className="row">
                      <div className="col-lg-12 col-xl-3">
                        <div className="edit-image">
                          <div className="user-image">{imagePreview}</div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-9">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                            <label className="mb-0" htmlFor="firstName">
                              {lang("Usermanagement.edituser.labels.firsname")}
                            </label>
                            <div className="w-100 text-readonly">
                              <span>{firstName}</span>
                            </div>
                          </div>
                          {lastName && lastName !== "" ? (
                            <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                              <label className="mb-0" htmlFor="lastName">
                                {lang(
                                  "Usermanagement.edituser.labels.lastname"
                                )}
                              </label>
                              <div className="w-100 text-readonly">
                                {lastName}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                            <label className="mb-0" htmlFor="email">
                              {lang("Usermanagement.edituser.labels.email")}
                            </label>
                            <div className="w-100 text-readonly">{email}</div>
                          </div>

                          {mobile && mobile !== "" ? (
                            <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                              <label className="mb-0" htmlFor="email">
                                {lang(
                                  "Usermanagement.edituser.labels.phoneNumber"
                                )}
                              </label>
                              <div className="w-100 text-readonly">
                                {mobile}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-5">
                  <div className="card profile-card">
                    <h6 className="d-flex align-items-center title mb-4">
                      <i className="bx bx-user mr-1" />
                      Personal Info
                    </h6>

                    {dob && (
                      <div className="form-group d-flex align-items-center mb-md-4 mb-3 user-details">
                        <label className="mb-0" htmlFor="BirthDate">
                          {lang("Usermanagement.edituser.labels.birthdate")}
                        </label>
                        <fieldset className="position-relative w-100 text-readonly">
                          {dob}
                        </fieldset>
                      </div>
                    )}
                    {wight ? (
                      <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                        <label className="mb-0" htmlFor="wight">
                          {lang("Usermanagement.edituser.labels.wight")}
                        </label>
                        <div className="w-100 text-readonly">{wight}</div>
                      </div>
                    ) : null}

                    {height ? (
                      <div className="d-flex align-items-center mb-md-4 mb-3 user-details">
                        <label className="mb-0" htmlFor="height">
                          {lang("Usermanagement.edituser.labels.height")}
                        </label>
                        <div className="w-100 text-readonly">{height}</div>
                      </div>
                    ) : null}
                    {address ? (
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="Address">
                          {lang("Usermanagement.edituser.labels.address")}
                        </label>
                        <div className="w-100 text-readonly">{address}</div>
                      </div>
                    ) : null}
                    {activity_goal ? (
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="activity-goal">
                          Activity Goal
                        </label>
                        <div className="w-100 text-readonly">
                          {activity_goal}
                        </div>
                      </div>
                    ) : null}
                    {active_minute_goal ? (
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="activity-minute-goal">
                          Activity Minute Goal
                        </label>
                        <div className="w-100 text-readonly">
                          {active_minute_goal}
                        </div>
                      </div>
                    ) : null}
                    {activity_level ? (
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="activity-level">
                          Activity Level
                        </label>
                        <div className="w-100 text-readonly">
                          {activity_level}
                        </div>
                      </div>
                    ) : null}
                    {energy_generate_goal ? (
                      <div className="d-flex align-items-center user-details mb-md-5 mb-3">
                        <label className="mb-0" htmlFor="energy-generate-goal">
                          Energy Generate Goal
                        </label>
                        <div className="w-100 text-readonly">
                          {energy_generate_goal}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © Energym</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state, ownProps) => {
  return {
    language: state.admin.language,
    details: state.admin.users[ownProps.match.params.slug],
  };
};
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps)(ViewuserDetails);
