import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";
import { en } from "./translations";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import FirebaseProvider from './Firebase';
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // default language to use
  resources: {
    en: {
      language: en, // 'common' is our custom namespace
    }
  },
});

/******************* 
@Purpose : This page is default rander page for our project
@Parameter : {}
@Author : INIC
******************/
ReactDOM.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <FirebaseProvider>
        <App />
      </FirebaseProvider>
    </I18nextProvider>
  </StrictMode>,
  document.getElementById("root")
);
serviceWorker.register();
