import React, { useState, useEffect, useRef, useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { Select } from "antd";
import "rc-pagination/assets/index.css";
import { updateUserStatus, getUsers } from "../../store/Actions";
// Used for api call
import swal from "sweetalert";
import _ from "lodash";
import debounce from "debounce";

import $ from "jquery";
import Loader from "../../components/Loader/Loader";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useOnClickOutside  } from "../../hooks";
import { Button } from "react-bootstrap";
import { showModalNotification } from "../../utils/Functions";
$.DataTable = require("datatables.net");
require("datatables.net-responsive");


const editUserStatus = debounce((cb, id, status) => cb(id, status), 1000);
var { Option } = Select;
/******************* 
@Purpose : Used for users list view
@Parameter : props
@Author : INIC
******************/
function UsersList(props) {
  const [columnSettingsArr, setColumnSettingsArr] = useState([]);
  const [columnSettings, setColumnSettings] = useState({
    photo: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobile: true,
    emailVerificationStatus: true,
    status: true,
  });
  const [usersList, setUsersList] = useState(props.users);
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(props.users.length);
  const [length, setLength] = useState(props.users.length);
  const [loading, setLoading] = useState(true);
  const [, setUserAccess] = useState({});
  const [filterpopup, openFilterpopup] = useState(false);
  const [downloadpopup, openDownloadpopup] = useState(false);
  const [userStatus, setUserStatus] = useState(true);

  const refFilter = useRef();
  const refDownload = useRef();
  const onCloseFilter = useCallback(() => openFilterpopup(false), []);
  useOnClickOutside(refFilter, onCloseFilter);
  const onCloseDownload = useCallback(() => openDownloadpopup(false), []);
  useOnClickOutside(refDownload, onCloseDownload);

  useEffect(() => {
    props.getUsers((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    });
  }, []);

  useEffect(() => {
    setUsersList(props.users);
  }, [props.users]);

  /******************* 
  @Purpose : Used for pagination
  @Parameter : Pagination Type: Prev or Next
  @Author : INIC
  ******************/
  const paginationChange = (type) => {
    setLoading(true);
    props.getUsers((err) => {
      if (err) {
        showModalNotification("Something Went Wrong", "error");
      }
      setLoading(false);
    }, "", type="next" ? props.next : props.prev);
  };

  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = debounce((keyword) => {
    setLoading(true);
    props.getUsers((err) => {
      setLoading(false);
      if (err) {
        swal({
          title: err.message | "somehting went wrong",
          icon: "Alert",
          buttons: true,
          dangerMode: true,
        });
      }
    }, keyword);
  }, 1000);

  /******************* 
  @Purpose : Used for handle UserStatus status
  @Parameter : {status,index}
  @Author : INIC
  ******************/
  const userStatusHandler = (status, index) => {
    let newUserList = [...usersList];
    newUserList[index].is_active = !status;
    setUsersList(newUserList);
    editUserStatus(props.updateUserStatus, newUserList[index].userid, !status);
  };

  let { photo } = columnSettings;
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block overflow-auto">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="content-header-title border-0 ">Users</li>
              </ol>
            </nav>
            <div class="content-area position-relative">
              <div class="grid">
                <div class="grid-content">
                  <div class="user-listing-filterOptions mb-2 d-block">
                    <div class="row mb-2">
                      <div class="col-sm-8 position-static">
                        <div class="left-buttons d-flex ">
                          <label>
                            <div className="search-input-wrapper position-relative">
                              <i className="bx bx-search"></i>{" "}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="search"
                                onChange={(evt) =>
                                  searchField(evt.target.value)
                                }
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? <Loader /> : null}
                </div>
              </div>
            </div>
            {!loading ? (
              <div className="table-responsive">
                <table
                  class="table row-border nowrap common-datatable"
                  id="user-listing"
                >
                  <thead>
                    <tr>
                      {photo ? (
                        <th>
                          <b>Profile</b>
                        </th>
                      ) : null}
                      <th>
                        <b>FullName</b>
                      </th>
                      <th>
                        <b>Email</b>
                      </th>
                      <th>
                        <b>Mobile</b>
                      </th>
                      <th>
                        <b>Username</b>
                      </th>
                      <th>
                        <b>Status</b>
                      </th>
                      <th class="all">
                        <b>Action</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList && usersList.length === 0 ? (
                      <tr className="text-center text-danger not-found-txt">
                        <td colSpan="6">
                          {!loading ? (
                            <h6
                              className="text-center text-danger not-found-txt"
                              colSpan="6"
                            >
                              No Records Found!
                            </h6>
                          ) : null}
                        </td>
                      </tr>
                    ) : (
                      usersList &&
                      Array.isArray(usersList) &&
                      usersList.map((user, key) => {
                        return (
                          <tr>
                            {photo ? (
                              <td>
                                <div className="thumb-img position-relative">
                                  <span class="position-absolute img-loader spinner-grow spinner-grow-sm"></span>
                                  {user.profile_photo &&
                                  user.profile_photo !== "" ? (
                                    <img
                                      src={
                                        user.profile_photo
                                          ? user.profile_photo
                                          : "/assets/images/no-image-user.png"
                                      }
                                      alt="/assets/images/no-image-user.png"
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/images/no-user.png"}
                                      alt="/assets/images/no-image-user.png"
                                    />
                                  )}
                                </div>
                              </td>
                            ) : null}
                            <td>{user.full_name}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile_number}</td>
                            <td>{user.username}</td>
                            <td>
                              <div className="custom-control custom-switch light">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={user.userid}
                                  checked={!user.is_active ? false : true}
                                  onChange={() =>
                                    userStatusHandler(user.is_active, key)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  for={user.userid}
                                ></label>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex ml-3">
                                <a
                                  onClick={() =>
                                    props.history.push(
                                      `/userdetails/${user.userid}`
                                    )
                                  }
                                  class="cursor-pointer mr-3"
                                >
                                  <i class="bx bx-show-alt"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            ) : null}
            {!loading ? 
            <div className="d-flex mt-3">
              {props.prev ? (
                <Button size="sm" className="mr-2" onClick={() => paginationChange("prev")}>
                  Previous
                </Button>
              ) : null}
              {props.next ? (
                <Button size="sm" onClick={() => paginationChange("next")}>
                  Next
                </Button>
                ) : null}
            </div>
            :null}
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between"></div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  UserAccess:
    state.admin.adminData &&
    state.admin.adminData.staticRolePermission &&
    state.admin.adminData.staticRolePermission.userAccess,
  users: _.values(state.admin.users),
  next: state.admin.user_page_next,
  prev: state.admin.user_page_prev
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { updateUserStatus, getUsers })(
  UsersList
);
