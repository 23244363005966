import app from "firebase";
import "firebase/auth";
import { FIRBASE_CONFIG } from "../config";

const base = app.initializeApp(FIRBASE_CONFIG);
const db = app.firestore(base);
export const fireStorage = base.storage("gs://energym-9f022.appspot.com");
export const fireStorageRef = base.storage("gs://energym-9f022.appspot.com").ref();
export default  db;


