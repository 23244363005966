import React, { useEffect, useState, useContext } from 'react';
import { setItem } from '../utils/Functions';


export const FirebaseContext = React.createContext(null);

export const useFirebase = () => {
    return useContext(FirebaseContext);
}
export default function Firebase({children}) {
    const [currentUser, setCurrentUser] = useState(null);
    function login(email,password){
        return new Promise((resolve, reject) => {
            email === "admin@gmail.com" && password === "Admin123#" ? resolve() : reject("please enter valid email and password");
        })
    }
    function logout() {
        localStorage.removeItem("accessToken");
    }

    return (
        <FirebaseContext.Provider value={{currentUser,login,logout}}>
            {children}
        </FirebaseContext.Provider>
    )
}
