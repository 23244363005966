const INTIAL_STATE = {
  language: "en",
  theme: true,
  resize: true,
  sidebar: true,
  users: [],
  master_cms: [],
  banners: [],
  user_page_next: null,
  user_page_prev: null,
  posts: [],
  post_page_next: null,
  post_page_prev: null,
  posts_report: [],
  report_page_next: null,
  report_page_prev: null,
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "theme":
      return Object.assign({}, state, {
        theme: action.payload,
      });
    case "resize":
      return Object.assign({}, state, {
        resize: action.payload,
      });
    case "sidebar":
      return Object.assign({}, state, {
        sidebar: action.payload,
      });
    case "language":
      return Object.assign({}, state, { language: action.payload.data });
    case "LOGOUT":
      return Object.assign({}, state, {
        adminData: {},
        adminProfileData: {},
        editAdminProfileData: {},
        edituserId: "",
        editEmailId: "",
        language: "en",
      });
    case "GET_USERS":
      return Object.assign({}, state, {
        users: action.payload,
        user_page_next: action.nextIndex,
        user_page_prev: action.prevIndex,
      });
    case "EDIT_USER_STATUS":
      let newUsers = { ...state.users };
      newUsers[action.payload.userid].is_active = action.payload.is_active;
      return Object.assign({}, state, { banners: newUsers });
    case "MASTER_CMS":
      return Object.assign({}, state, { cms: action.payload });
    case "GET_BANNERS":
      return Object.assign({}, state, { banners: action.payload });
    case "ADD_EDIT_BANNER": {
      let newBanners = { ...state.banners };
      newBanners[action.payload.bannerid] = action.payload;
      return Object.assign({}, state, { banners: newBanners });
    }
    case "EDIT_BANNER_STATUS": {
      let newBanners = { ...state.banners };
      newBanners[action.payload.bannerid].is_active = action.payload.is_active;
      return Object.assign({}, state, { banners: newBanners });
    }
    case "DELETE_BANNER": {
      let newBanners = { ...state.banners };
      delete newBanners[action.payload.bannerid];
      return Object.assign({}, state, { banners: newBanners });
    }
    case "GET_PLACEHOLDERS":
      return Object.assign({}, state, { placeholders: action.payload });
    case "ADD_EDIT_PLACEHOLDER": {
      let newPlaceholders = { ...state.placeholders };
      newPlaceholders[action.payload.placeholderid] = action.payload;
      return Object.assign({}, state, { placeholders: newPlaceholders });
    }
    case "DELETE_PLACEHOLDER": {
      let newPlaceholders = { ...state.placeholders };
      delete newPlaceholders[action.payload.placeholderid];
      return Object.assign({}, state, { placeholders: newPlaceholders });
    }
    case "GET_POSTS":
      return Object.assign({}, state, {
        posts: action.payload,
        post_page_next: action.nextIndex,
        post_page_prev: action.prevIndex,
      });
    case "EDIT_POST_STATUS":
      let newPosts = { ...state.posts };
      newPosts[action.payload.userid].is_active = action.payload.is_active;
      return Object.assign({}, state, { banners: newPosts });
    case "GET_POSTS_REPORT":
      return Object.assign({}, state, {
        posts_reports: action.payload,
        report_page_next: action.nextIndex,
        report_page_prev: action.prevIndex,
      });
    default:
      return state;
  }
};
